import { useEffect, useRef } from 'react';

import useRequest from '@Hooks/useRequest';

import type { FC, PropsWithChildren } from 'react';

export interface ModalBaseProps extends PropsWithChildren {
  id: string;
  view: 'success' | 'error';
}

const ModalBase: FC<ModalBaseProps> = (props) => {
  const { id, view, children } = props;
  const { country } = useRequest();
  const refSended = useRef(false);

  useEffect(() => {
    if (refSended.current) return;

    refSended.current = true;
  }, [id, country, view]);

  return <>{children}</>;
};

export default ModalBase;
